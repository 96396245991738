import React, { useState, forwardRef } from "react";
import ReactInputMask from "react-input-mask";

const iconMap = {
  user: "fa-user",
  edit: "fa-pencil-alt",
  supervisor: "fa-user-tie",
  users: "fa-users",
  code: "fa-hashtag",
  password: "fa-key",
  eye: "fa-eye",
  eyeSlash: "fa-eye-slash",
};

const InputComponent = forwardRef(
  (
    {
      label,
      placeholder,
      inputHelperText,
      type,
      maxLength,
      error,
      mask,
      ...props
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    const getInputType = () => {
      if (type === "password") {
        return showPassword ? "text" : "password";
      }
      if (type === "number") {
        return "number";
      }
      return "text";
    };

    const InputOrMaskedInput = mask ? (
      <ReactInputMask mask={mask} {...props}>
        {(inputProps) => (
          <input
            {...inputProps}
            ref={ref}
            id="input-icon"
            type={getInputType()}
            placeholder={placeholder}
            maxLength={maxLength}
            className={`input ${error ? "error" : ""}`}
          />
        )}
      </ReactInputMask>
    ) : (
      <input
        ref={ref}
        id="input-icon"
        type={getInputType()}
        placeholder={placeholder}
        maxLength={maxLength}
        className={`input ${error ? "error" : ""}`}
        {...props}
      />
    );

    return (
      <div className={`br-input ${error ? "error" : ""}`}>
        <label htmlFor="input-icon">{label}</label>
        <div className="input-group">
          {type && iconMap[type] && (
            <div className="input-icon">
              <i className={`fas ${iconMap[type]}`} aria-hidden="true" />
            </div>
          )}
          {InputOrMaskedInput}
          {inputHelperText && (
            <span className="feedback danger" role="alert">
              <i className="fas fa-times-circle" aria-hidden="true" />
              {inputHelperText}
            </span>
          )}
          {type === "password" && (
            <button
              className="br-button"
              type="button"
              aria-label="Mostrar senha"
              onClick={togglePasswordVisibility}
            >
              <i
                className={`fas ${
                  showPassword ? iconMap.eyeSlash : iconMap.eye
                }`}
                aria-hidden="true"
              />
            </button>
          )}
        </div>
      </div>
    );
  },
);

export default InputComponent;
