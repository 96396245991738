import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Modal } from "antd";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import GenericService from "../../services/GenericService";
import { useSnackbar } from "../../context/SnackbarProvider";
import SearchTableComponent from "../../components/Table/SearchTableComponent";
import SearchSelectComponent from "../../components/SearchSelectComponent/SearchSelectComponent";

export const associationDetailsLoader = async ({ params }) => {
  try {
    const responseReport = await GenericService.findOne(
      "find-reporting",
      params.id,
    );

    const responseAssociation = await GenericService.findAllList(
      `reporting/${params.id}/pointInterest`,
    );

    if (responseReport.status === 200 && responseAssociation) {
      const transformedContent = responseAssociation.data.content.map(
        (item) => ({
          id: item.id,
          observation: item.observation,
          indicM: item.indicM,
          indicP: item.indicP,
          type: item.pointInterest.type.type,
          typeDescription: item.pointInterest.typeDescription,
          pointInterestId: item.pointInterest.id,
          typeId: item.pointInterest.type,
        }),
      );
      const transformedAssociationData = {
        ...responseAssociation.data,
        content: transformedContent,
      };

      return {
        reportDetails: responseReport.data,
        associations: transformedAssociationData,
      };
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error loading association details:", error);
  }
  return {};
};

export default function ResultSearchReport() {
  const { handleSubmit, control, watch, reset } = useForm({
    resolver: yupResolver(),
  });
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const { reportDetails } = useLoaderData();
  const [associations, setAssociations] = useState({
    loading: true,
    content: [],
  });
  const [selectedAssociation, setSelectedAssociation] = useState(null);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [sortInfo, setSortInfo] = useState({ id: null, type: null });

  const columns = [
    { id: "title", label: "Título", isSort: true },
    { id: "homologateAt", label: "Homologado em", date: true, isSort: true },
    { id: "id", label: "ID", isSort: true },
    { id: "type", label: "Tipo" },
    { id: "team", label: "Equipe" },
    { id: "status", label: "Status" },
    { id: "relations", label: "Relacionado" },
  ];

  const selectedType = watch("type");
  const typeSelected = selectedType
    ? typeOptions.find((option) => option.name === selectedType.name)
    : null;

  const handleReportView = () => {
    navigate(`/pesquisar-relatorios`);
  };

  const getAssociations = async (page = 0, size = pageSize) => {
    let query = `page=${page}&size=${size}`;
    if (sortInfo.id && sortInfo.type) {
      query += `&sortBy=${sortInfo.id}&order=${sortInfo.type}`;
    }

    try {
      const responseAssociation = await GenericService.findAllList(
        `reporting/${reportDetails.id}/pointInterest?${query}`,
      );

      if (responseAssociation) {
        const transformedContent = responseAssociation.data.content.map(
          (item) => ({
            id: item.id,
            observation: item.observation,
            indicM: item.indicM,
            indicP: item.indicP,
            type: item.pointInterest.type.type,
            typeDescription: item.pointInterest.typeDescription,
            pointInterestId: item.pointInterest.id,
            typeId: item.pointInterest.type,
          }),
        );
        const transformedAssociationData = {
          ...responseAssociation.data,
          content: transformedContent,
        };

        // setAssociations(transformedAssociationData);
      }
    } catch (error) {
      showSnackbar({
        error: true,
        message: `Erro ao carregar associações: ${error}`,
      });
    }
  };

  const handleAssociationFocus = (id) => {
    const associationFocus = associations.content.find(
      (association) => association.id === id,
    );
    setSelectedAssociation(associationFocus);
  };

  const handleCloseModalView = () => {
    setOpenModalView(!openModalView);
  };

  const handleChangePage = async (page) => {
    setCurrentPage(page);
    await getAssociations(page, pageSize);
  };

  const handleChangeSize = async (size) => {
    setPageSize(size);
    setCurrentPage(0); // Quando alterar o tamanho da página, resetar para a primeira página
    await getAssociations(0, size);
  };

  useEffect(() => {
    const objects = {
      loading: true,
      content: [
        {
          title: "Empresa ACME suspeita de contrabando",
          homologateAt: "2024-02-10T14:30:00.000Z",
          id: 1,
          type: "teste",
          team: "RF01",
          status: "Homologado",
          relations: "3 relatórios",
        },
        {
          title: "Empresa ACME suspeita de contrabando",
          homologateAt: "2024-02-10T14:30:00.000Z",
          id: 2,
          type: "teste",
          team: "RF01",
          status: "Homologado",
          relations: "2 relatórios",
        },
        {
          title: "Empresa XYZ envolvida em fraude fiscal",
          homologateAt: "2024-02-11T10:15:00.000Z",
          id: 3,
          type: "auditoria",
          team: "RF02",
          status: "Pendente",
          relations: "5 relatórios",
        },
        {
          title: "Investigação sobre evasão de divisas",
          homologateAt: "2024-02-12T08:45:00.000Z",
          id: 4,
          type: "investigação",
          team: "RF03",
          status: "Em andamento",
          relations: "4 relatórios",
        },
        {
          title: "Investigações",
          homologateAt: "2024-02-09T16:00:00.000Z",
          id: 5,
          type: "denúncia",
          team: "RF04",
          status: "Homologado",
          relations: "6 relatórios",
        },
        {
          title: "Lavagem de dinheiro identificada",
          homologateAt: "2024-02-08T12:30:00.000Z",
          id: 6,
          type: "auditoria",
          team: "RF05",
          status: "Finalizado",
          relations: "3 relatórios",
        },
      ],
    };
    // Teste loading
    setInterval(() => {
      setAssociations(objects);
    }, 1500);
  }, []);

  return (
    <Box>
      <Box marginY={3}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={3}
        >
          <Typography variant="h4">Resultados da Pesquisa</Typography>
          <Box display="flex" gap={2}>
            <SearchSelectComponent
              options={["Mais antigo", "Mais atual"]}
              placeholder="Mais antigo"
            />
            <ButtonComponent
              title="Refazer pesquisa"
              icon="reload"
              onClick={() => handleReportView()}
            />
          </Box>
        </Box>
      </Box>
      <Grid container display="flex" gap={3}>
        <SearchTableComponent
          columns={columns}
          actions={["info", "plus"]}
          data={associations}
          changeSize={handleChangeSize}
          changePage={handleChangePage}
          onClickView={(data) => {
            navigate("/relatorios/novo-relatorio");
          }}
          onClickInfo={(data) => {
            setOpenModalView(true);
          }}
        />
      </Grid>
      <Modal
        footer={null}
        open={openModalView}
        onClose={handleCloseModalView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onCancel={handleCloseModalView}
        width="50%"
      >
        <Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Relatórios relacionados
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Box>aa</Box>
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
}
