import React from "react";
import { Grid, Pagination, useTheme } from "@mui/material";

export default function SearchTableFooter(props) {
  const theme = useTheme();
  const {
    count,
    currentPage,
    totalPages,
    rowsPerPage,
    setCurrentPage,
    setRowsPerPage,
  } = props;

  const handleChangePage = (event) => {
    setCurrentPage(parseInt(event.target.value, 10));
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <Grid container>
      <Grid
        item
        display="flex"
        justifyContent="center"
        sx={{
          margin: "20px 0",
        }}
        xs={12}
      >
        <Pagination
          classes="footer-pagination-v2"
          sx={{
            "& .MuiPaginationItem-root": {
              color: "#1351B4", // Cor dos números
              fontWeight: "bold",
            },
            "& .MuiPaginationItem-root.Mui-selected": {
              color: "white",
            },
          }}
          color="primary"
          count={1}
        />
      </Grid>
    </Grid>
  );
}
