import { useCallback } from "react";
import useSWR from "swr";
import GenericService from "../services/GenericService";
import { useSnackbar } from "../context/SnackbarProvider";

const useHourBalance = () => {
  const { showSnackbar } = useSnackbar();

  // Criar saldo de horas
  const createHourBalance = useCallback(async (orderId, payload) => {
    try {
      const response = await GenericService.create(
        `expendTime/${orderId}`,
        payload,
      );
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error(response.data?.message || "Erro ao criar saldo de horas");
    } catch (error) {
      throw new Error(`Erro ao criar saldo de horas: ${error}`);
    }
  }, []);

  /// buscar todos os saldos de horas
  const useGetHourBalanceWithRequester = (orderId, page, size) => {
    const { data, error, isValidating, mutate } = useSWR(
      orderId ? `expendTime/all/${page}${size}` : null,
      async () => {
        const response = await GenericService.findOne(
          "expendTime",
          orderId,
          { onlyrequester: "false" },
          {
            page,
            size,
          },
        );
        if (response.status === 200) {
          return response.data;
        }
        throw new Error(
          response.data?.message ||
            "Erro ao buscar saldo de horas com requester",
        );
      },
    );
    return {
      hourBalanceAll: data,
      isLoadingHourBalanceAll: !error && !data,
      isErrorHourBalanceUserAll: error,
      isValidatingHourBalanceAll: isValidating,
      mutateHourBalanceAll: mutate,
    };
  };

  // Buscar saldo de horas do usuário logado
  const useGetHourBalance = (orderId, page, size) => {
    const { data, error, isValidating, mutate } = useSWR(
      orderId ? `expendTime/user/${page}${size}` : null,
      async () => {
        const response = await GenericService.findOne(
          "expendTime",
          orderId,
          {},
          {
            page,
            size,
          },
        );
        if (response.status === 200) {
          return response.data;
        }
        throw new Error(
          response.data?.message || "Erro ao buscar saldo de horas",
        );
      },
    );

    return {
      hourBalanceUser: data,
      isLoadingHourBalanceUser: !error && !data,
      isErrorHourBalanceUser: error,
      isValidatingHourBalanceUser: isValidating,
      mutateHourBalanceUser: mutate,
    };
  };

  const deleteHourBalance = useCallback(
    async ({ day, timeExpended, userName, userId }, isSupervisor, orderId) => {
      try {
        // Construindo a URL com os parâmetros necessários
        let url = `expendtime/remove/?orderId=${orderId}&date=${day}`;
        if (isSupervisor) {
          url += `&idResearcher=${userId}`;
        }

        const response = await GenericService.deleteOne(url);

        if (
          response.status === 200 ||
          response.status === 202 ||
          response.status === 204
        ) {
          return true;
        }

        throw new Error(
          response.data?.message || "Erro ao excluir saldo de horas",
        );
      } catch (error) {
        showSnackbar(
          error.response?.data?.message || "Erro ao excluir saldo de horas",
          "error",
        );
        throw error;
      }
    },
    [showSnackbar],
  );

  const editHourBalance = useCallback(
    async (
      { day, timeExpended, userName, userId },
      orderId,
      minuteDifference,
    ) => {
      try {
        // Definir isToReduce com base no sinal de minuteDifference
        const isToReduce = minuteDifference < 0 ? "true" : "false";

        // Garantir que expendTime seja sempre positivo
        const expendTime = Math.abs(minuteDifference);

        // Construindo a URL com os parâmetros necessários
        const url = `add/expendtime/${orderId}?&date=${day}&expendTime=${expendTime}&idResearcher=${userId}`;

        const response = await GenericService.create(url, [], { isToReduce });

        if ([200, 202, 204].includes(response.status)) {
          return true;
        }

        throw new Error(
          response.data?.message || "Erro ao editar saldo de horas",
        );
      } catch (error) {
        showSnackbar(
          error.response?.data?.message || "Erro ao editar saldo de horas",
          "error",
        );
        throw error;
      }
    },
    [showSnackbar],
  );

  return {
    createHourBalance,
    useGetHourBalance,
    useGetHourBalanceWithRequester,
    deleteHourBalance,
    editHourBalance,
  };
};

export default useHourBalance;
