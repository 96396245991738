import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Modal } from "antd";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { format, isValid, parseISO } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import InputComponent from "../../../components/InputComponent/InputComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import "./style.css";

const newAssociationFormValidationSchema = yup.object({
  hour: yup
    .number()
    .typeError("O campo deve ser um número")
    .required("O campo ”hora(s)” não pode ficar vazio")
    .min(0, "A hora não pode ser menor que 0")
    .max(23, "A hora não pode ser maior que 23"),
  minutes: yup
    .number()
    .typeError("O campo deve ser um número")
    .required("O campo ”minuto(s)” não pode ficar vazio")
    .min(0, "Os minutos não podem ser menores que 0")
    .max(59, "Os minutos não podem ser maiores que 59"),
});

function formatDate(dateString) {
  if (!dateString) return "01/01/1900"; // Valor padrão
  const date = parseISO(dateString); // Tenta parsear a data
  if (!isValid(date)) return "01/01/1900"; // Retorna padrão se inválida
  return format(date, "dd/MM/yyyy");
}

// Função para calcular a diferença em minutos entre timeExpended e o novo valor digitado
function calculateMinuteDifference(originalTime, newHour, newMinutes) {
  const [originalHour, originalMinutes] = originalTime.split(":").map(Number);

  const originalTotalMinutes = originalHour * 60 + originalMinutes;
  const newTotalMinutes = newHour * 60 + newMinutes;

  return newTotalMinutes - originalTotalMinutes;
}

export default function ModalEditBalanceHour({
  openModal,
  closeModal,
  onFormSubmit,
  hourBalance,
}) {
  const { handleSubmit, control, setValue, reset, watch } = useForm({
    resolver: yupResolver(newAssociationFormValidationSchema),
  });

  // Effect para preencher automaticamente os campos de hora e minutos
  useEffect(() => {
    if (openModal && hourBalance?.timeExpended) {
      const [hour, minutes] = hourBalance.timeExpended.split(":").map(Number);
      setValue("hour", hour);
      setValue("minutes", minutes);
    }
  }, [openModal, hourBalance, setValue]);

  const handleClearFilters = () => {
    reset();
    closeModal();
  };

  function handleAplicar(data) {
    const { hour, minutes } = data;

    if (hourBalance?.timeExpended) {
      const minuteDifference = calculateMinuteDifference(
        hourBalance.timeExpended,
        hour,
        minutes,
      );

      onFormSubmit({ ...data, minuteDifference });
    }

    reset();
    closeModal();
  }

  return (
    <Modal
      title={
        <span style={{ fontFamily: "Rawline", fontWeight: 600 }}>
          Editar saldo de horas
        </span>
      }
      open={openModal}
      width={600}
      onCancel={() => {
        reset();
        closeModal();
      }}
      footer={null}
    >
      <Box display="flex" flexDirection="column" marginTop={3} gap={1}>
        <Typography variant="p">
          Informe o tempo dedicado na seguinte data:
        </Typography>
        <Box display="flex" gap={1}>
          <Typography variant="p6">Data: </Typography>
          <Typography variant="p">{formatDate(hourBalance?.day)}</Typography>
        </Box>
        <form onSubmit={handleSubmit(handleAplicar)}>
          <Box display="flex" gap={2}>
            <Controller
              name="hour"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <InputComponent
                  {...field}
                  label="Hora(s)"
                  placeholder="00"
                  inputHelperText={error?.message}
                  inputType="hour"
                  type="number"
                />
              )}
            />
            <Controller
              name="minutes"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <InputComponent
                  {...field}
                  label="Minuto(s)"
                  placeholder="00"
                  inputHelperText={error?.message}
                  type="number"
                />
              )}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" marginTop={3} gap={2}>
            <ButtonComponent
              buttonType="secondary"
              title="Cancelar"
              onClick={handleClearFilters}
            />
            <ButtonComponent title="Salvar" icon="save" submit />
          </Box>
        </form>
      </Box>
    </Modal>
  );
}
