import React, { useEffect, useState } from "react";
import {
  Chip,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faEdit,
  faPlus,
  faEye,
  faInfoCircle,
  faSort,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { format, parseISO } from "date-fns";
import TableFooter from "./TableFooter";
import theme from "../../theme/theme";
import SearchTableFooter from "./SearchTableFooter";

function FontAwesomeSortIcon({ orderDirection, isActive, isHovered }) {
  let icon;
  if (isActive) {
    icon = orderDirection === "asc" ? faCaretUp : faCaretDown;
  } else {
    icon = faSort;
  }

  const iconOpacity = isActive || isHovered ? 1 : 0;
  return <FontAwesomeIcon icon={icon} style={{ opacity: iconOpacity }} />;
}

function CustomTableSortLabel({
  active,
  direction,
  valueToOrderBy,
  columnId,
  ...restProps
}) {
  const [hover, setHover] = useState(false);

  return (
    <TableSortLabel
      active={active}
      direction={direction}
      IconComponent={() => (
        <FontAwesomeSortIcon
          isActive={valueToOrderBy === columnId}
          orderDirection={direction}
          isHovered={hover}
        />
      )}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...restProps}
    />
  );
}

function formatDate(dateString) {
  const date = parseISO(dateString);
  return format(date, "dd/MM/yyyy");
}

function removeSecondsFromTime(time) {
  const parts = time.split(":");
  return `${parts[0]}:${parts[1]}`;
}

export default function SearchTableComponent({
  columns,
  actions,
  data,
  onClickInfo,
  onClickView,
  onClickDelete,
  changeSize,
  changePage,
  onSortChange,
}) {
  return (
    <>
      <Table>
        <TableBody>
          {data && data.content && data.content.length > 0 ? (
            data.content.map((row) => (
              <TableRow hover key={row.id}>
                {columns.map((column) => {
                  let value = row[column.id];
                  if (column.format) {
                    value = column.format(value);
                  } else if (column.date) {
                    value = formatDate(value);
                  } else if (column.time) {
                    value = removeSecondsFromTime(value);
                  }
                  return (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: column.orderRight
                          ? "right !important"
                          : "left",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      <strong>{column.label}</strong> <br />
                      {column.id === "relations" ? (
                        <Chip
                          label={value}
                          sx={{
                            borderRadius: "3px",
                          }}
                          size="small"
                          color="primary"
                        />
                      ) : (
                        value
                      )}
                    </TableCell>
                  );
                })}
                {actions && (
                  <TableCell
                    sx={{
                      borderBottom: "1px solid #000",
                    }}
                  >
                    {actions.includes("info") && (
                      <IconButton
                        sx={{
                          "&:hover": {
                            backgroundColor: theme.customColors.blueWarm200,
                          },
                          "&:active": {
                            backgroundColor: theme.customColors.blueWarm200,
                          },
                        }}
                        onClick={() => onClickInfo(row.id)}
                      >
                        <Tooltip title="Detalhes">
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            color={theme.customColors.blueWarm700}
                          />
                        </Tooltip>
                      </IconButton>
                    )}
                    {actions.includes("plus") && (
                      <IconButton
                        sx={{
                          "&:hover": {
                            backgroundColor: theme.customColors.blueWarm200,
                          },
                          "&:active": {
                            backgroundColor: theme.customColors.blueWarm200,
                          },
                        }}
                        onClick={() => onClickView(row.id)}
                      >
                        <Tooltip title="Visualizar">
                          <FontAwesomeIcon
                            icon={faPlus}
                            color={theme.customColors.blueWarm700}
                          />
                        </Tooltip>
                      </IconButton>
                    )}
                    {actions.includes("delete") && (
                      <IconButton
                        sx={{
                          "&:hover": {
                            backgroundColor: theme.customColors.blueWarm200,
                          },
                          "&:active": {
                            backgroundColor: theme.customColors.blueWarm200,
                          },
                        }}
                        onClick={() => onClickDelete(row.id)}
                      >
                        <Tooltip title="Excluir">
                          <FontAwesomeIcon
                            icon={faTrash}
                            color={theme.customColors.blueWarm700}
                          />
                        </Tooltip>
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length + (actions ? 1 : 0)}
                align="center"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {data && data.loading === true ? (
                  <CircularProgress />
                ) : (
                  "Nenhum dado encontrado"
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <SearchTableFooter
        count={data?.totalElements || 1} // Total de linhas
        currentPage={data?.number || 0} // Página atual
        totalPages={data?.totalPages || 1} // Total de páginas
        rowsPerPage={data?.size || 5} // Linhas por página
        setCurrentPage={(pageNumber) => changePage(pageNumber)} // Função para atualizar a página atual
        setRowsPerPage={(sizeNumber) => changeSize(sizeNumber)}
      />
    </>
  );
}
