import React, { useState, useEffect, forwardRef, useRef } from "react";
import "./style.css";
import img from "./img.png";

export default function SearchSelectComponent({ options, onChange }) {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div className="search-select-component">
      <select value={selectedValue} onChange={handleChange} name="" id="">
        {options &&
          options.map((r) => (
            <option key={r} value={r}>
              {r}
            </option>
          ))}
      </select>
    </div>
  );
}
